var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-package-service","no-header":"","visible":!!_vm.value},on:{"hidden":function($event){return _vm.$emit('input', null)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('service.package_service.add_a_service')))]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"packageServiceFormRef",staticClass:"p-5",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('navigation.categories'),"label-for":"categories"}},[_c('category-tree-select',{attrs:{"id":"categories","lang":_vm.$store.state.appConfig.lang},model:{value:(_vm.categories),callback:function ($$v) {_vm.categories=$$v},expression:"categories"}})],1),_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$tc('common.service'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$tc('common.service'),"label-for":"organizationHelicopter"}},[_c('v-select',{attrs:{"input-id":"organizationHelicopter","placeholder":_vm.$tc('common.service'),"label":"id","options":_vm.servicesOptions,"searchable":false},on:{"open":function($event){return _vm.fetchServicesOptions()},"search":function (search) {
                _vm.searchLoader = true
                _vm.searchServices(search)
              }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
              var search = ref.search;
return [(_vm.searchLoader)?_c('b-spinner',{attrs:{"small":""}}):(search)?[_vm._v(_vm._s(_vm.$t('common.no_results_found_for', { search: search })))]:[_vm._v(_vm._s(_vm.$t('common.no_results_found')))]]}},{key:"selected-option",fn:function(ref){
              var name = ref.name;
return [_vm._v(" "+_vm._s(_vm._f("trans")(name))+" ")]}},{key:"option",fn:function(ref){
              var name = ref.name;
return [_vm._v(" "+_vm._s(_vm._f("trans")(name))+" ")]}},{key:"list-footer",fn:function(ref){
              var search = ref.search;
return [(_vm.servicesDataMeta.totalItems > 12 && !_vm.searchLoader && _vm.servicesDataMeta.lastPage > 1)?_c('li',{staticClass:"pagination"},[_c('button',{staticClass:"w-100",attrs:{"type":"button","disabled":!_vm.servicesDataMeta.previousPage},domProps:{"textContent":_vm._s(_vm.$t('common.previous'))},on:{"click":function($event){return _vm.fetchServicesOptions(_vm.servicesDataMeta.previousPage, search || null)}}}),_c('button',{staticClass:"w-100",attrs:{"type":"button","disabled":!_vm.servicesDataMeta.nextPage},domProps:{"textContent":_vm._s(_vm.$t('common.next'))},on:{"click":function($event){return _vm.fetchServicesOptions(_vm.servicesDataMeta.nextPage, search || null)}}})]):_vm._e()]}}],null,true),model:{value:(_vm.selectedPackageService),callback:function ($$v) {_vm.selectedPackageService=$$v},expression:"selectedPackageService"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('app-form-actions',{staticClass:"mt-5",attrs:{"form-name":"deposit-zone-form","show-submit-and-next":"","submit-disabled":_vm.packageServiceLoading},on:{"cancel":hide,"reset":function($event){_vm.selectedPackageService = null},"save-and-next":_vm.saveAndNext}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }